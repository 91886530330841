
@mixin maier-button() {
    display: inline-block;
    outline: none !important;
    padding: 1.5rem 6rem;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    white-space: normal;
}

%maier-button {
    @include maier-button;
}

@mixin maier-button-primary() {
    @include maier-button;

    background-color: $maier-background-color-tertiary;
    color: $maier-text-color-tertiary;

    &:hover, &:focus-visible {
        background-color: $maier-color-orange;
    }
}

%maier-button-primary {
    @include maier-button-primary;
}

@mixin maier-button-secondary() {
    @include maier-button;

    background-color: $maier-color-orange;
    color: $maier-text-color-tertiary;

    &:hover, &:focus-visible {
        background-color: $maier-background-color-secondary;
        color: $maier-text-color-primary;
    }
}

%maier-button-secondary {
    @include maier-button-secondary;
}

@mixin maier-button-tertiary() {
    @include maier-button;

    background-color: $maier-background-color-secondary;
    color: $maier-text-color-primary;

    &:hover, &:focus-visible {
        background-color: $maier-color-orange;
        color: $maier-text-color-tertiary;
    }
}

%maier-button-tertiary {
    @include maier-button-tertiary;
}

@mixin maier-button-quaternary() {
    @include maier-button;

    background-color: $maier-color-white;
    border: 1px solid $maier-color-lighter-grey;
    font-size: $maier-font-xxx-small;
    line-height: 2.5rem;

    &:hover, &:focus-visible {
        border-color: $maier-color-orange;
        color: $maier-color-orange;
    }
}

%maier-button-quaternary {
    @include maier-button-quaternary;
}

@mixin maier-button-rolex() {
    @include maier-button;

    background-color: #127749;
    border: 1px solid #127749;
    border-radius: 30px;
    color: $maier-text-color-tertiary;

    &:hover, &:focus-visible {
        background-color: $maier-text-color-tertiary;
        border: 1px solid #127749;
        color: #127749;
    }
}

%maier-button-rolex {
    @include maier-button-rolex;
}

@mixin maier-button-availability() {
    @include maier-button;

    background-color: $maier-color-black;
    color: $maier-color-white;
    font-size: $maier-font-x-small;
    line-height: 2.8rem;
}

%maier-button-availability {
    @include maier-button-availability;
}

@mixin maier-button-availability-inverse() {
    @include maier-button;

    border: 1px solid $maier-color-black;
    display: block;
    font-weight: $maier-font-weight-medium;
    padding: 1rem 4rem;
}

%maier-button-availability-inverse {
    @include maier-button-availability-inverse;
}

@mixin maier-button-personalize() {
    background: linear-gradient(to right, #A39CCB, #B0C9CA, #A2B868, #DCCE45, #F0BE52);
    flex-basis: calc(50% - 0.5rem);
    font-size: $maier-font-x-small;
    font-weight: $maier-font-weight-medium;
    padding: 1.5rem;
}

%maier-button-personalize {
    @include maier-button-personalize;
}

.maier-button {
    &.maier-button-primary {
        @include maier-button-primary;
    }

    &.maier-button-secondary {
        @include maier-button-secondary;
    }

    &.maier-button-tertiary {
        @include maier-button-tertiary;
    }

    &.maier-button-rolex {
        @include maier-button-rolex;
    }

    &.maier-button-scroll-to-top {
        background-color: $maier-color-orange;
        bottom: 10rem;
        height: 5rem;
        position: fixed;
        opacity: 0;
        right: 0;
        transition: opacity .5s, visibility .5s;
        visibility: hidden;
        width: 5rem;
        z-index: $zindex-navigation;

        @include respond-to("medium") {
            display: none;
        }

        &:before {
            content: url('/images/global/icons/arrow-up-white.svg');
            color: $maier-color-white;
            font-size: $maier-font-larger;
            left: 50%;
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%);
        }

        &.visible {
            opacity: 1;
            visibility: visible;
        }
    }

    &.maier-button-left {
        float: left;
        align-self: start;
    }

    &.maier-button-right {
        float: right;
        align-self: end;
    }
}

.apple-pay-button:not(apple-pay-button) {
    @extend %maier-button-primary;

    cursor: pointer;
    font-size: $maier-font-x-small;
    margin: 0;

    @include respond-to('extra-large') {
        padding: 1.5rem 3rem;
    }

    img {
        height: 20px;
    }
}